import React from "react"
import { motion } from "framer-motion"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Button from "../components/Button/Button"
import ColorPicker from "../components/ColorPicker/ColorPicker"

const ColorsPage = () => {
  const colors = [
    { primary: "#ffffff", secondary: "#000000", accent: "#00ffff" },
    { primary: "#000000", secondary: "#ffffff", accent: "#00ffff" },
    { primary: "#000000", secondary: "#08AEEE", accent: "#ffffff" },
    { primary: "#FFF26E", secondary: "#862C8E", accent: "#862C8E" },
    { primary: "#0CA984", secondary: "#862C8E", accent: "#862C8E" },
    { primary: "#F6B1AD", secondary: "#783432", accent: "#783432" },
  ]

  return (
    <Layout>
      <SEO title="Colors!" />
      <motion.div id={"colors-container"}>
        <motion.div style={{ margin: "auto auto" }}>
          <motion.div id={"color-themes"}>
            {colors.map((theme, index) => (
              <ColorPicker
                primary={theme.primary}
                secondary={theme.secondary}
                key={index}
              />
            ))}
          </motion.div>
          <Button text={"BACK TO HOME"} to={"/"} />
        </motion.div>
      </motion.div>
    </Layout>
  )
}

export default ColorsPage
