import React, { useContext } from "react"
import { motion } from "framer-motion"

import "./color-picker.css"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

const ColorPicker = ({ primary, secondary }) => {
  const dispatch = useContext(GlobalDispatchContext)

  const handleClick = () => {
    dispatch({
      type: "SET_THEME",
      payload: {
        primary: primary,
        secondary: secondary,
        accent: "#00ffff",
      },
    })
  }

  return (
    <motion.div
      className={"color-picker-container"}
      style={{ backgroundColor: primary, border: `1px solid ${secondary}` }}
      onClick={handleClick}
    ></motion.div>
  )
}

export default ColorPicker
